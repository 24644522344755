<template>
  <div class="modal filter-modal promt-create-modal offer-modal accept-offer">
    <div class="overlay" @click="$parent.closeAcceptOfferModal"></div>
    <div class="wrapper">
      <div class="container">
        <div>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="modal-head">
                <div class="close" @click="$parent.closeAcceptOfferModal">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_168_1950)">
                      <path
                        d="M18 6L6 18"
                        stroke="#2E2E2E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 6L18 18"
                        stroke="#2E2E2E"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_168_1950">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div class="bottom">
                <div class="title small">Contact informations</div>
                <label>
                  <input type="text" placeholder="Name" v-model="name" />
                </label>
                <label>
                  <input type="text" placeholder="Surname" v-model="surname" />
                </label>
                <label>
                  <input
                    type="email"
                    placeholder="E-mail address"
                    v-model="email"
                  />
                </label>
                <label>
                  <input type="tel" placeholder="Phone" v-model="phone" />
                </label>
                <label>
                  <select v-model="country">
                    <option value="" disabled selected>Country</option>
                    <option
                      v-for="(item, i) in countryOptions"
                      :value="item"
                      :key="i"
                    >
                      {{ item.title }}
                    </option>
                  </select>
                </label>
                <label>
                  <input type="text" placeholder="City" v-model="city" />
                </label>
                <label>
                  <input type="text" placeholder="Address" v-model="address" />
                </label>
                <label>
                  <input
                    type="text"
                    placeholder="Post Code"
                    v-model="postCode"
                  />
                </label>
              </div>
              <div class="bottom">
                <div class="title small">Payment detail</div>
                <input
                  type="text"
                  pattern="\d*"
                  maxlength="19"
                  v-model="card_number"
                  placeholder="Card number"
                />
                <input
                  type="text"
                  v-model="card_holder_name"
                  placeholder="Card holder name"
                />
                <div class="input-row">
                  <input
                    type="text"
                    pattern="\d*"
                    maxlength="2"
                    v-model="expire_month"
                    placeholder="Expiration date month"
                  />
                  <input
                    type="text"
                    pattern="\d*"
                    maxlength="4"
                    v-model="expire_year"
                    placeholder="Expiration date year"
                  />
                </div>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms" />
                        <div class="checkbox"></div>
                        <span class="title">I agree with </span>
                        <a @click="$parent.goToPage('privacy')" class="title">
                          privacy notice
                        </a>
                        <span class="title"> and</span>
                        <a @click="$parent.goToPage('terms')" class="title">
                          terms and conditions
                        </a>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">
                  {{ $parent.error }}
                </div>
              </transition>
              <div class="buttons">
                <button
                  :disabled="!requiredFieldsAreFilled"
                  class="button blue"
                  @click="confirm"
                >
                  <span>Confirm</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AcceptOfferModal",
  props: [],
  components: {},
  data: function () {
    return {
      card_holder_name: "",
      card_number: "",
      expire_month: "",
      expire_year: "",
      email: "",
      name: "",
      surname: "",
      phone: "",
      country: "",
      countryId: "",
      city: "",
      address: "",
      postCode: "",
      terms: false,
      countryOptions: [],
      countryOptionsId: [],
    };
  },
  mounted() {
    this.getCounties();
    this.$http
      .get(process.env.VUE_APP_API + "user/profile")
      .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignIn();
        }
      });
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if (
        this.name &&
        this.surname &&
        this.email &&
        this.phone &&
        this.country &&
        this.city &&
        this.address &&
        this.postCode &&
        this.terms &&
        this.card_holder_name &&
        this.card_number &&
        this.expire_month &&
        this.expire_year
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    },
  },
  methods: {
    getCounties() {
      this.$http
        .get(process.env.VUE_APP_API + "countries")
        .then((res) => {
          this.countryOptions = res.data.payload;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    confirm() {
      let data = {
        id: this.$parent.activeOffer.id,
        card_number: this.card_number,
        card_holder_name: this.card_holder_name,
        expire_month: this.expire_month,
        expire_year: this.expire_year,
        name: this.name,
        surname: this.surname,
        email: this.email,
        phone: this.phone,
        country: this.country.title,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
        currency: this.$parent.currencyCode,
        amount: this.$parent.activeOffer.service_price,
      };

      this.$http
        .post(process.env.VUE_APP_API + "offer/withdraw/card", data)
        .then(() => {
          this.$parent.closeAcceptOfferModal();
          this.$parent.closeOfferModal();
          this.$parent.openDealModal();
          this.$parent.getCreatedHistory();
        })
        .catch((res) => {
          if (res.response.data.errors) {
            const firstErrors = {};
            for (const key in res.response.data.errors) {
              if (
                res.response.data.errors.hasOwnProperty(key) &&
                Array.isArray(res.response.data.errors[key]) &&
                res.response.data.errors[key].length > 0
              ) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.$emit("setError", firstErrors[key]);
              }
            }
          } else {
            this.$emit("setError", res.response.data.message);
          }
        });
    },
  },
};
</script>
