<template>
  <main class="main page-inside text-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="stroke-text">
          {{ pageTitle }}
        </div>
        <transition name="fade">
          <div class="wrapper" v-if="pageTitle">
            <div class="title big">{{pageTitle}}</div>
            <div class="desc" v-html="pageHeader"></div>
            <div class="desc" v-html="pageContent"></div>
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  props: ['identifier'],
  components: {
  },
  data: function() {
    return {
      pageTitle: '',
      pageHeader: '',
      pageContent: '',
      loading: true
    }
  },
  mounted() {
    this.getStaticPageContent(this.identifier || this.$route.params.identifier);
  },
  watch: {
    '$route.params.identifier': function(newIdentifier) {
      this.getStaticPageContent(newIdentifier);
    }
  },
  methods: {
    getStaticPageContent(identifier) {
      this.loading = true;
      this.pageTitle = '';
      this.pageHeader = '';
      this.pageContent = '';
      
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + identifier)
        .then((res) => {
          this.pageTitle = res.data.payload.title;
          this.pageContent = res.data.payload.content;
          this.pageHeader = res.data.payload.header || '';
          this.loading = false;
        })
        .catch((error) => {
          console.error('Error loading static page:', error);
          this.loading = false;
        });
    }
  }
}
</script>