<template>
  <div class="menu">
    <div class="wrpper">
      <div class="row">
        <div class="column">
          <div class="title small">
            Useful
          </div>
          <ul class="nav" v-if="$parent.textPageList.length">
            <template v-for="item in $parent.textPageList">
              <li class="nav__item" :key="item.id">
                <div @click="$parent.goToPage(item.slug || item.id)" class="desc">
                  {{item.title}}
                </div>
              </li>
            </template>
          </ul>
          <ul class="nav">
            <li class="nav__item">
              <router-link 
                to="/faq" 
                class="desc" 
                v-text="'FAQ'"
              />
            </li>
            <li class="nav__item">
              <router-link 
                to="/contacts" 
                class="desc" 
                v-text="'Contact Us'"   
              />
            </li>
          </ul>
        </div>
        <div class="column" v-if="$parent.categories && $parent.categories.length">
          <div class="title small">
            Images
          </div>
          <ul class="nav nav-categories">
            <div 
              class="link"
              v-for="(item, i) in $parent.categories" 
              :key="i" 
            >
              <router-link 
                :to="'/product-list/' + item.title.toLowerCase().replace(/\s+/g, '_')" 
                class="item desc" 
                v-text="item.title" 
              />
            </div>
          </ul>
        </div>
        <div class="column">
          <div class="title small">
            Video
          </div>
          <div class="desc">
            <span>Coming soon</span>
          </div>
        </div>
      </div>
      <div class="header-profile header-profile-mob" v-if="$parent.isAuth">
        <a @click.prevent="$parent.openTopUpModal()" class="button cart topup-balance">
          <img class="img" src="./../img/top_up.svg"/>
          <div v-if="$parent.userBalance">
            € {{ $parent.userBalance }}
          </div>
        </a>
        <router-link class="profile-desc" to="/profile/my-orders">
          <div class="icon">
            <img class="img" src="./../img/user.svg"/>
          </div>
        </router-link>
      </div>
      <div class="header-profile-mob" v-else>
        <a class="button" @click="$emit('openSignInModal')">
          Sign In
        </a>
      </div>
      <div class="select-container cart" v-if="curr && ($parent.currencyOptions.length > 1)">
        <select class="select currency-select" v-model="curr"
                @change="selectCurr()"
        >
          <option :value="item" v-for="item in currOptions" :key="item">{{ item }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Menu',
  props: ['currencyCode', 'currencyValue', 'categories'],
  data: function () {
    return {
      curr: ''
    }
  },
  components: {
  },
  computed: {
    currOptions() {
      return this.$parent.currencyOptions.map(({code}) => code);
    }
  },
  watch: {
    currencyCode: function (newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSignUpModal() {
      this.$emit('openSignUpModal')
    },
    scrollToSection(section) {
      this.$parent.closeMenu();
      this.$emit('scrollToSection', section)
    },
    selectCurr() {
      this.changeCurrency(this.curr)
      localStorage.setItem("currency", this.curr);
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
  },
}
</script>
